import React, { useState } from "react"
import { graphql } from 'gatsby'
import LayoutPublic from "../components/layouts/layoutPublic"
import { Box, Button, Heading, Image, SimpleGrid, Spinner, Flex, Stack, Text } from "@chakra-ui/react"
import Header from "../components/header/index"
import AdsSummary from "../components/lists/adsSummary"
import ModelAdsTable from "../components/ModelAdsTable"
import ModelStats from "../components/ModelStats"
import ModelAlertSubscribeForm from "../components/Alerts/ModelAlertSubscribeForm"
import WysiwygContent from "../components/content/content";
import Seo from '../components/Seo/';
import StructuredProductComparison from "../components/StructuredData/productComparison";
import VersionsList from '../components/VersionsList.js'
import Loadable from 'react-loadable'
import VisibilitySensor from 'react-visibility-sensor'
import HrefLang from '../utils/HrefLang'
import { FormattedMessage } from 'react-intl'
import europeMap from '../images/europeMap.svg'
import ModelCharts from '../components/Charts/'
import Wrapper from '../components/Wrapper'
import Nav from "../components/nav"
import { wrapperPadding } from "../utils/wrapperPadding"

const ModelTpl = (props) => {
  // console.log(props)

  const
    [isAdsVisible, setAdsVisible] = useState(false),
    [isMapVisible, setMapVisible] = useState(false);

  const {
    contentful_id,
    name,
    slug,
    refUnivers,
    refBrand,
    refVersions,
    childContentfulModelContentSecondaryRichTextNode,
    crew,
    launchYear,
    weight,
    designer,
    vipEmailExample,
    imageAsset
  } = props.data.model

  const {
    modelOtherLang,
    ads,
    models
  } = props.data


  const { universID } = props.pageContext
  const modelStat = props.data.stats ? props.data.stats.edges[0].node : null;
  const adsBecameVisible = (isVisible) => {
    if (isVisible && !isAdsVisible) {
      setAdsVisible(true)
    }
  }

  const AsyncAdsPanel = (isAdsVisible) ? Loadable({
    loader: () => import('../components/ad/AsyncAdsPanel'),
    loading() {
      return <Flex
        mx={{ base: 0, md: "2rem", lg: "2rem", xxl: "10rem" }}
        justify="center"
        border="dashed 2px"
        borderRadius="lg"
        borderColor="gray.100"
        background="gray.50"
        p="1rem"
        my="1rem"
        key={0}><Spinner color="blue.500" size="sm" />
      </Flex>
    }
  }) : null

  const ModelMap = (isMapVisible) ?
    Loadable({
      loader: () => import("../components/Maps/ModelMap"),
      loading() {
        return <Box>Chargement de la carte...</Box>
      }
    })
    : null

  const title = {
    "fr":
      name +
      " " +
      refUnivers.singularName.toLowerCase() +
      ": infos du bateau, détails de la série, actu, prix + occasion à vendre, achat/vente"
    ,
    "en":
      "Used " +
      name +
      " " +
      refUnivers.name.toLowerCase() +
      " boat for sale, second hand : price/buying/selling a boat"
  };
  const description = {
    "fr":
      name +
      " " +
      refUnivers.name.toLowerCase() +
      ", infos, détails, actu. Occasions au meilleur prix à vendre, annonces prix, achat, vente. Wanaboat : spécialiste achat/vente " +
      name +
      " d'occasion"
    ,
    "en":
      "Second hand " + name + " " + refUnivers.singularName.toLowerCase() + " used and at the best price for sale in Europe, price listings, buy, sell. iWannaboat, the specialist in buying and selling used " + name + " " + refUnivers.name.toLowerCase()
  };

  return (
    <LayoutPublic lang={process.env.GATSBY_LANG}>
      {modelOtherLang ? <HrefLang lang="fr" slug={modelOtherLang.slug} /> : null}
      <Seo
        title={
          title[process.env.GATSBY_LANG]
        }
        description={description[process.env.GATSBY_LANG]

        }
        slug={slug}
      />
      <StructuredProductComparison
        name={name}
        lowPrice={2000}
        highPrice={10000}
        offerCount={ads ? ads.edges.length : 0}
        refBrand={refBrand}
        slug={slug}
        images={null}
      />
      <Header
        nav={<Nav />}
        image={imageAsset}
        name={name}
        breadcrumbsItems={[
          refUnivers,
          refBrand ? refBrand.length === 1 ? refBrand[0] : null : null,
          { "name": name, "slug": slug }
        ]
        }
        // singuralName={singuralName}
        slug={slug}
        descShort={null}
        refModel={{ slug: slug }}
        refBrand={refBrand}
        backLinkTarget={refUnivers.slug}
        desc={
          <>
            <WysiwygContent data={JSON.parse(props.data.model.description.raw)} references={props.data.model.description.references} />
            <ModelAlertSubscribeForm name={name} modelId={contentful_id} placeholer={vipEmailExample} />
          </>
        }
      />


      <Stack
        spacing={{ base: 2, lg: 6 }}
        {...wrapperPadding()}
      >
        <SimpleGrid gap='2rem' columns={{ base: 1, lg: 2 }}>
          <Box>
            <AdsSummary
              ads={ads.edges.filter((item, index) => index < 5 ? item : null)}
              universName={name}
              // orientation='portrait'
              columns={1}
            />
          </Box>
          <Box
            maxH={{ base: 'auto', lg: '550px' }}
            overflowY='scroll'
          >
            <Heading as="h2" textTransform="uppercase" fontSize="18px" fontWeight="normal" color="blue.600" letterSpacing=".05rem" mb="1.5rem">
              <FormattedMessage id="model.presentation.title" values={{ modelName: name }} />
            </Heading>
            <VersionsList
              modelName={name}
              versions={refVersions}
            />
            {props.data.model.contentSecondary ?
              <>
                <WysiwygContent
                  data={JSON.parse(props.data.model.contentSecondary.raw)}
                  references={props.data.model.contentSecondary.references}
                />
              </>
              : null}
          </Box>
        </SimpleGrid>
        {ads.edges.length > 0 ?
          <>
            <SimpleGrid
              columns={{ base: 1, lg: 2 }}
              gridGap="2rem"
            >
              <ModelStats
                ads={ads}
                modelName={name}
                universName={refUnivers.singularName.toLowerCase()}
                soldBoats={modelStat ? modelStat.soldBoats : null}
                launchYear={launchYear}
                boatDesigner={designer}
                boatCrew={crew}
                boatWeight={weight ? weight.toString() : null}
              />
              <Box
                display={{ base: 'none', lg: 'initial' }}
                position="relative"
                borderRadius="md"
                overflow="hidden"
              >
                {isMapVisible && ModelMap ?
                  <ModelMap ads={ads} />
                  :
                  <>
                    <Image
                      src={europeMap}
                      alt="Map"
                      borderRadius='10px'
                      h='500px'
                      w='100%'
                      objectFit='cover'
                    />
                    <Flex
                      justifyContent="center"
                      alignItems="center"
                      position="absolute"
                      top='0'
                      left='0'
                      w='100%'
                      h='100%'
                      background='radial-gradient(rgba(255,255,255,.5), rgba(255,255,255,.2))'
                      onClick={() => { setMapVisible(true) }}
                      cursor='pointer'
                    >
                      <Button
                        colorScheme="green"
                        bg='green.700'
                        boxShadow="lg"
                        onClick={() => { setMapVisible(true) }}
                      >
                        <FormattedMessage id="model.see.europe.map.of.model" values={{ modelName: name }} />
                      </Button>
                    </Flex>
                  </>
                }
              </Box>
            </SimpleGrid>
            {/* <ModelCharts ads={ads} modelID={contentful_id} name={name} /> */}

            <VisibilitySensor
              onChange={adsBecameVisible}
            >
              {isAdsVisible ?
                <AsyncAdsPanel
                  baseSlug={slug}
                  minPrice={0}
                  maxPrice={1000}
                  universID={universID}
                  modelID={contentful_id}
                  models={models}
                  isModelsMobileOpen={false}
                  scrolling={false}
                  switchViewForBrowsing={() => { this.setState({ isUserFilters: true }) }}
                  openSelf={false}
                />
                :
                <Flex
                  id='annonces'
                  mx={{ base: 0, md: "2rem", lg: "2rem", xxl: "10rem" }}
                  justify="center"
                  border="dashed 2px"
                  borderRadius="lg"
                  borderColor="gray.100"
                  background="gray.50"
                  p="1rem"
                  my="1rem"
                  key={0}><Spinner color="blue.500" size="sm" />
                </Flex>}
            </VisibilitySensor>
          </>
          :
          <Box
            px='2rem'
            mx='auto'
            py='3rem'
          >
            <Box textAlign='center'>
              <Text
                display='inline-block'
                fontSize='20px'
                textAlign='center'
                borderBottom={{ lg: 'solid 1px' }}
                borderColor='blue.brand'
              >
                <FormattedMessage id='model.no.ad.notice.title' values={{ name: name }} />
                {' '}<span role='img' aria-label='Triste'>😢</span>
              </Text>
            </Box>
            <Text
              color='gray.500'
              textAlign='center'
            >
              <FormattedMessage id='model.no.ad.notice' />
            </Text>
          </Box>
        }
        <Box
          p={{ base: '1rem', lg: '4rem' }}
          bg='white'
        >
          <Heading as="h2" textTransform="uppercase" fontSize="18px" fontWeight="normal" color="blue.600" letterSpacing=".05rem" mb="1.5rem">
            <FormattedMessage id="model.price.table.title" values={{ modelName: name }} />
          </Heading>
          <ModelAdsTable ads={ads} modelName={name} link={true} />
        </Box>
      </Stack>
    </LayoutPublic>
  )
}

export default ModelTpl


export const pageQuery = graphql`
  query modelQuery($slug: String!, $modelID: String!, $contentfulID: String!, $lang: String!, $otherLang: String! ) {
    model: contentfulModel(id: { eq: $modelID }) {
      contentful_id
      slug
      name
      imageAsset {
        gatsbyImageData(
          width:600,
          height:600,
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
      vipEmailExample
      crew
      launchYear
      designer
      weight
      description{
        raw
        references {
          ... on ContentfulBrand { contentful_id slug }
          ... on ContentfulModel { contentful_id slug }
          ... on ContentfulUnivers { contentful_id slug }
        }
      }
      contentSecondary{
        raw
        references {
          ... on ContentfulBrand { contentful_id slug }
          ##... on ContentfulModel { contentful_id slug }
          ##... on ContentfulUnivers { contentful_id slug }
        }
      }
      #childContentfulModelDescriptionRichTextNode {
      #  json
      #}
      #childContentfulModelContentSecondaryRichTextNode {
      #  json
      #}
      refUnivers {
        name
        singularName
        slug
      }
      refVersions {
        id
        name
        slug
      }
      refBrand {
        name
        slug
      }
      modelCharacteristics {
        data {
          key
          value
        }
      }
      stolenData {
        name
        mail
        phone
        date
        content
      }
      ad{
        id
        name
        slug
        price
        department
        isBoat
        isPart
        isSail
        isTrolley
        refBrand {
          name
        }
        images {
          url
        }
        pictures { url }
        user{
          id
        }
        date(formatString: "Y")
        publicationDate(formatString: "D/M/Y")
        refUnivers {
          slug
        }
        location {
          lat
          lon
        }
      }
    }
    modelOtherLang: contentfulModel( contentful_id: { eq: $contentfulID}, node_locale: { eq: $otherLang }){
      slug
    }
    ads: allContentfulAd(
      filter: {
          refModel: { slug: { eq: $slug } }
          node_locale: { eq: $lang }
      }
      sort: { fields: [publicationDate], order: DESC }
    ) {
      edges {
        node {
          contentful_id
          id
          name
          slug
          price
          department
          isBoat
          isPart
          isSail
          isTrolley
          country
          refModel{
            name
            slug
          }
          refBrand {
            name
          }
          images {
            url
          }
          pictures { url }
          user{
            id
          }
          date(formatString: "Y")
          publicationDate(formatString: "D/M/Y")
          refUnivers {
            slug
          }
          location {
            lat
            lon
          }
        }
      }
    }
    boatAdsByPrice: allContentfulAd(
      filter: {
        isBoat: { eq: true }
        price: { gt: 1 }
        refModel: { id: { eq: $modelID } }
      }
      sort: { fields: [price], order: ASC }
    ) {
      edges {
        node {
          id
          name
          slug
          price
          department
          isBoat
          isPart
          isSail
          isTrolley
          refBrand {
            name
          }
          images {
            url
          }
          pictures { url }
          date(formatString: "Y")
          publicationDate(formatString: "D/M/Y")
          refUnivers {
            slug
          }
        }
      }
    }
    cheapAd: allContentfulAd(
      filter: { refModel: { slug: { eq: $slug } } }
      limit: 1
      sort: { fields: price, order: ASC }
    ) {
      edges {
        node {
          price
        }
      }
    }
    expensiveAd: allContentfulAd(
      filter: { refModel: { slug: { eq: $slug } } }
      limit: 1
      sort: { fields: price, order: DESC }
    ) {
      edges {
        node {
          price
        }
      }
    }
    boatOnlyAds: allContentfulAd(
      filter: { isBoat: { eq: true }, refModel: { slug: { eq: $slug } } }
    ) {
      edges {
        node {
          price
        }
      }
    }
    partOnlyAds: allContentfulAd(
      filter: { isPart: { eq: true }, refModel: { slug: { eq: $slug } } }
    ) {
      edges {
        node {
          price
        }
      }
    }
    sailOnlyAds: allContentfulAd(
      filter: { isSail: { eq: true }, refModel: { slug: { eq: $slug } } }
    ) {
      edges {
        node {
          price
        }
      }
    }
  }
`;