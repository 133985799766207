import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { Box, Link, Text } from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'

const VersionsList = (props) => {
    const LinkSuffix = (linksProps) => {
        if ((linksProps.index + 1) === props.versions.length) {
            return '.'
        } else {
            return ', '
        }
    }
    if (!props.versions || !props.modelName) { return null }
    return (
        <Box
            mb='2rem'
        >
            <Text>
                {/* La série {props.model.name} a {props.versions.length} différentes :{' '} */}
                <FormattedMessage id='model.versions.title' values={{ 'modelName': props.modelName, 'versionsCount': props.versions.length }} />:{' '}
                {props.versions.map((version, index) =>
                    <Box
                        key={`model-version-link-${version.id}`}
                        as="span"
                    >
                        <Link
                            as={GatsbyLink}
                            to={`${version.slug}`}
                        >{version.name}</Link><LinkSuffix index={index} />
                    </Box>
                )}
            </Text>
        </Box>
    )
}

export default VersionsList