import React, { useState, useEffect } from "react"
import { FormattedMessage } from "react-intl"
import { Box, Heading } from "@chakra-ui/react"
import { Radar } from 'react-chartjs-2';
import { useIntl } from 'react-intl'

const ModelPricesBrandsCharts = (props) => {
    const translations = useIntl()['messages']

    const { data } = props

    const getValue = (data, key) => {
        var values = []
        for (let index = 0; index < data.length; index++) {
            const element = data[index][key] ? data[index][key] : translations['model.stats.other.brands'];
            values.push(element)
        }
        return (values)
    }
    const barChartData = {
        labels: getValue(data, 'name'),
        // responsive: true,
        // maintainAspectRatio: false,
        datasets: [{
            label: 'Valeur moyenne',
            backgroundColor: 'rgba(0,66,218, .4)',
            borderColor: '#555555',
            borderWidth: 0,
            data: getValue(data, 'averagePrice')
        }]

    }
    if (props.ads.edges.length < 2) {
        return null
    }

    return (
        <Box
            id="charts"
            w='100%'
        >
            {/* <pre>
                { JSON.stringify( translations, null, 1 )}
            </pre> */}
            <Box
                p={{ base: "1rem" }}
            >
                <Heading as='h3' mb='1rem' textTransform="uppercase" fontWeight="normal" fontSize="16px" color="blue.600" letterSpacing=".05rem">
                    <FormattedMessage id="model.charts.price.brand.title" values={{ modelName: props.name }} />
                </Heading>

                {data ?
                    <Radar
                        data={barChartData}
                        options={{
                            maintainAspectRatio: true,
                            width: '100%',
                            height: '100%',
                            title: { display: false },
                            legend: { display: false },
                            scale: {

                                ticks: {
                                    suggestedMin: 0,
                                    suggestedMax: Math.max(...getValue(data, 'averagePrice'))
                                }
                            }
                        }}
                    />
                    : null}
            </Box>
        </Box>
    )
}

export default ModelPricesBrandsCharts