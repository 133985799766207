import React, { useState, useEffect } from "react"
import { FormattedMessage } from "react-intl"
import { Box, Heading } from "@chakra-ui/react"
import { Bar } from 'react-chartjs-2';

const ModelPricesCharts = (props) => {
    const { data } = props
    const getValue = (data, key) => {
        var values = []
        for (let index = 0; index < data.length; index++) {
            const element = data[index][key];
            values.push(element)
        }
        return (values)
    }
    const barChartData = {
        labels: getValue(data, '_id'),
        responsive: true,
        maintainAspectRatio: false,
        datasets: [{
            label: 'Valeur moyenne',
            backgroundColor: '#0042da',
            borderColor: '#555555',
            borderWidth: 0,
            data: getValue(data, 'averagePrice'),
            minBarLength: 45,
            barPercentage: 0.8,
        }]

    }

    if (props.ads.edges.length < 2) {
        return null
    }

    return (
        <Box
            id="charts"
            w='100%'
        >
            {/* <pre>
                { JSON.stringify( data, null, 1 )}
            </pre> */}
            <Box
                p={{ base: "1rem" }}
            >
                <Heading as='h3' mb='1rem' textTransform="uppercase" fontWeight="normal" fontSize="16px" color="blue.600" letterSpacing=".05rem">
                    <FormattedMessage id="model.charts.price.year.title" values={{ modelName: props.name }} />
                </Heading>

                {data ?
                    <Bar
                        data={barChartData}
                        options={{
                            maintainAspectRatio: props.aspectRatio,
                            width: '100%',
                            height: '100%',
                            title: { display: false },
                            legend: { display: false },
                            scales: {
                                yAxes: [{
                                    ticks: {
                                        // max : 1,    
                                        min: 0
                                    }
                                }]
                            }
                        }}
                    />
                    : null}
            </Box>
        </Box>
    )
}

export default ModelPricesCharts