import React, { useState, useEffect } from "react"
import axios from 'axios'


import ModelPricesCharts from "../ModelPricesCharts"
import ModelPricesBrandsCharts from "../ModelPricesBrandsCharts";

import {
    Box,
    SimpleGrid,
    Text
} from "@chakra-ui/react"
import { FormattedMessage } from "react-intl";

const ModelCharts = (props) => {

    const [data, setData] = useState(false)
    useEffect(() => {
        axios.get(`${process.env.GATSBY_API_URL}/model/stats/${props.modelID}`)
            .then(function (response) {
                // handle success
                setData(response.data)
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    }, [])
    const [modelPriceBrandData, setModelPriceBrandData] = useState(false)
    // useEffect(() => {
    //     axios.get(`${process.env.GATSBY_API_URL}models/prices/brand/${props.modelID}`)
    //         .then(function (response) {
    //             // handle success
    //             setModelPriceBrandData(response.data)
    //         })
    //         .catch(function (error) {
    //             // handle error
    //             console.log(error);
    //         })
    //         .then(function () {
    //             // always executed
    //         });


    // }, [])

    // const isFullBlockVisible = () => {
    //     if (modelPriceBrandData.length < 2 && modelPriceYearData < 2) { return false }
    //     else { return true }
    // }

    const calcColumnsNumber = () => {
        if (modelPriceBrandData.length < 3) { return (1) }
        else { return (2) }
    }
    if (
        // modelPriceYearData
        // && modelPriceBrandData
        // && isFullBlockVisible
        // && modelPriceBrandData.length > 2
        1 === 1
    ) {
        return (
            <Box
                my='1rem'
                h={{ lg: calcColumnsNumber() === 1 ? '300px' : 'auto' }}
                background='white'
                boxShadow='sm'
                borderRadius='md'
            >
                <SimpleGrid
                    columns={{ lg: calcColumnsNumber() }}

                >
                    {data && data.byYear ?
                        <ModelPricesCharts ads={props.ads} modelID={props.contentful_id} name={props.name} data={data.byYear} aspectRatio={calcColumnsNumber() === 1 ? false : true} />
                        : null}
                    {data && data.byBrand && data.byBrand.length > 3 ?
                        <ModelPricesBrandsCharts ads={props.ads} modelID={props.contentful_id} name={props.name} data={data.byBrand} />
                        : null}
                </SimpleGrid>
                <Text
                    p={2}
                    textAlign='center'
                    color='gray.600'
                    fontSize={{ base: '13px', lg: '14px' }}
                    display={{ base: 'none', lg: 'block' }}
                >
                    <FormattedMessage id='model.stats.notice' values={{ modelName: props.name }} />.
                </Text>
            </Box>
        )
    }
    else { return ('else') }
}

export default ModelCharts